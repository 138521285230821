import React from "react"
import styled from "styled-components"

const SubTitle = styled.h4`
    display: block;
    margin: 0 0 1.5rem 0;
    color: #999;
`
const Input = styled.input`
    width: 100%;
    padding: 0.5em;
    margin: 0.5em 0;
`
const NextButton = styled.div`
    padding: 0.5em;
    border: 1px solid #999;
    border-radius: 0.5em;
    width: fit-content;

    &:hover { 
        cursor: pointer;
    }
`

const ChooseApiKey = (props: any) => {
    const apiKeyInputRef = React.useRef<HTMLInputElement>(null);

    const handleClick = () => {
        if(apiKeyInputRef.current?.value.match(/^[A-Z0-9]{34}$/i)) {
            const key = apiKeyInputRef.current?.value.trim()
            props.callback({
                nextStep: 3,
                apiKey: key,
                api: props.apiEndpoint.replace("{apikey}", key)
            })
        }
    }

    const linkToGen = `https://${props.apiHostname?.replace(`api.`, ``)}/myapikey`;

    return (<>
        Enter your API key for {props.apiHostname}

        <SubTitle>
            You can generate it by going to <a href={linkToGen} target="_blank" rel="nofollow">{linkToGen}</a>. 
            This tool does everything client side so we don't send your API key to a backend server. Feel free to generate a key and then destroy it if you don't
            trust this tool.
        </SubTitle>

        <SubTitle>
            If you don't have an API key for this service, you can use this one, but it may be rate limited since it's public: <code>{props.apiKey}</code>
        </SubTitle>

        <Input ref={apiKeyInputRef} type="text" id="address" placeholder="Enter your API key" />
        <NextButton onClick={() => handleClick()}>Next</NextButton>
    </>)
}

export default ChooseApiKey
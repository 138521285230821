import React from "react"
import { Link } from "gatsby"
import Layout from "../../../components/Layout"
import styled from "styled-components"

import ChooseChain from "./0_choose_chain"
import ChooseAddress from "./1_choose_address"
import ChooseApiKey from "./2_choose_apikey"
import ShowResults from "./3_show_results"

interface IStep {
    selected: boolean;
    canSelect: boolean;
}

const Title = styled.h3`
    display: block;
    margin: 0 0 1rem 0;
`
const SubTitle = styled.h4`
    display: block;
    margin: 0 0 1.5rem 0;
    color: #999;
`
const Stepper = styled.div`
    display: block;
    border: 1px solid #999;
    width: fit-content;
    padding: 0 2em;
    margin: 2em 0;
`
const Steps = styled.ul`
    list-style-type: none;
`
const Step = styled.li<IStep>`
    display: inline-block;
    padding: 0.5em 1em;
    background: ${props => props.selected ? `#353535` : `transparent`};

    :hover {
        cursor: ${props => props.canSelect ? `pointer` : `not-allowed`};
    }

    @media (max-width: 992px) {
        display: block;
    }
`
const ResetButton = styled.div<IStep>`
    display: block;
    margin: 2em 0;
    padding: 0.5em 1em;
    background: #9B6161;
    width: fit-content;

    :hover {
        cursor: ${props => props.canSelect ? `pointer` : `not-allowed`};
    }
`

interface IState {
    step: number; // holds the current step the user is in the process
    chainId: number; // holds the chain id 
    address: string | null; // holds the 0x user address
    apiKey: string | null; 
    api: string | null; // holds the http api powered by etherscan
    rpc: string | null; // holds the http rpc endpoint
}

const DEFAULT_STATE = {
    step: 0,
    chainId: 1,
    address: null,
    apiKey: null,
    api: "",
    rpc: ""
}

class MyContracts extends React.Component<{}, IState> {
    state: IState = DEFAULT_STATE;

    constructor() {
        super({});

        this.renderSwitch = this.renderSwitch.bind(this);
        this.updateState = this.updateState.bind(this);
    }

    updateState(args: any) {
        this.setState({
            ...this.state,
            ...args,
            step: args.nextStep
        })
    }

    renderSwitch(step: number) {
        switch(step) {
            default :
            case 0 :
                return <ChooseChain 
                            callback={this.updateState} 
                        />
            case 1 :
                return <ChooseAddress 
                            callback={this.updateState} 
                            apiEndpoint={this.state.api} 
                        />
            case 2 :
                return <ChooseApiKey 
                            callback={this.updateState} 
                            apiEndpoint={this.state.api} 
                            apiHostname={new URL(this.state.api ?? "https://api.etherscan.io/").hostname}
                            apiKey={this.state.apiKey}
                        />
            case 3 :
                return <ShowResults 
                            apiEndpoint={this.state.api} 
                            apiHostname={new URL(this.state.api ?? "https://api.etherscan.io/").hostname} 
                            rpc={this.state.rpc}
                            address={this.state.address}
                        />
        }
    }

    render() {
        return (
            <Layout>
            <Title>
                <Link to="/">~/</Link>
                <Link to="/tools">tools/</Link>&nbsp;MyContracts
            </Title>
            <SubTitle>
                Find contracts created by an account!
            </SubTitle>
            <SubTitle>
                This tool makes use of the Etherscan (and their other block explorers) API to return the 
                most recent 10,000 transactions and then evaluate if the transaction <u>directly</u> created 
                a contract.
            </SubTitle>

            <Stepper>
                <Steps>
                    <Step 
                        onClick={() => this.state.step > 0 && this.updateState({nextStep: 0})} 
                        selected={this.state.step == 0}
                        canSelect={this.state.step > 0}
                    >
                        Choose Chain
                    </Step>
                    <Step 
                        // onClick={() => this.state.step > 1 && this.updateState({nextStep: 1})} 
                        selected={this.state.step == 1}
                        canSelect={false && this.state.step > 1}
                    >
                        Enter an Address
                    </Step>
                    <Step 
                        // onClick={() => this.state.step > 2 && this.updateState({nextStep: 2})} 
                        selected={this.state.step == 2}
                        canSelect={false && this.state.step > 2}
                    >
                        Enter API key
                    </Step>
                    <Step 
                        // onClick={() => this.state.step > 3 && this.updateState({nextStep: 3})} 
                        selected={this.state.step == 3}
                        canSelect={false && this.state.step > 3}
                    >
                        Results
                    </Step>
                </Steps>
            </Stepper>

            {this.renderSwitch(this.state.step)}

            {this.state.step > 0 && <ResetButton onClick={() => this.state.step > 0 && this.updateState({nextStep: 0})} selected={false} canSelect={true}>Start Over</ResetButton>}

            </Layout>
        );
    }
};

export default MyContracts;

import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { ethers } from "ethers"
import { CopyToClipboard } from 'react-copy-to-clipboard';

const NODE_KEY = "c047fc2c05444ccfbdf60ee05013f08a";

import TextRainbow from "../../../components/TextRainbow"
import Loading from "../../../components/Loading"

interface IViewSelector {
    selected: boolean;
}

const TotalCreated = styled.div`
    margin: 0.5em 0;
`
const Error = styled.div`
    margin: 0.5em 0;
    padding: 0.25em;
    background: #BE5125;
`
const ItemList = styled.ul`
    list-style-type: disc;
    margin: 0 1em;
`

const Item = styled.li`
    margin: 0.25em 0;
`
const ShowResultsJson = styled.div`
    margin: 2em 0 0 0;
`
const ViewSelector = styled.div<IViewSelector>`
    font-size: 80%;
    display: inline-block;
    margin: 0 1em;
    border: 1px solid #999;
    border-radius: 5%;
    padding: 0.5em;
    background: ${props => props.selected ? `#353535` : `transparent`};

    :hover {
        cursor: ${props => !props.selected ? `pointer` : `not-allowed`};
    }
`

const ShowResults = (props: any) => {
    const [isLoading, setIsLoading] = useState(true)
    const [totalToFetch, setTotalToFetch] = useState(0)
    const [prettyView, setPrettyView] = useState(true)
    const [error, setError] = useState("")
    const [results, setResults] = useState<{ hash: string; addr: string; block: number;}[]>([])

    useEffect(() => {
        getPastTransactions();
      }, []);

    const getTxReceipt = async (hash: string) => {
        const provider = new ethers.providers.JsonRpcProvider(props.rpc)
        console.log(`Fetching data for tx ${hash}`)
        return await provider.getTransactionReceipt(hash)
    }

    const getTransactionReceipts = async (txData: any[]) => {
        return Promise.all(
            txData.map(data => getTxReceipt(data.hash))
        )
    }

    const getPastTransactions = async () => {
        const res = await fetch(props.apiEndpoint)
            .then(response => response.json())
            .then(data => {
                if(data.message === "NOTOK") {
                    setIsLoading(false)
                    setError(data.result)
                    return []
                } 
                return data.result
            })
            .catch((e) => {
                console.log(e)
            })

        if(res.length) {
            // Filter out results for contract creations tx
            const contracts = res.filter( (tx: any) => tx.to == "")
            setTotalToFetch(contracts.length)

            // Get the transaction receipts for each tx to get the contract address
            await getTransactionReceipts(contracts).then( (res) => {
                const contractsCreated = res.map((result) => {
                        return {
                            hash: result?.transactionHash ?? `RPC: Error`,
                            addr: result?.contractAddress ?? `RPC: Error`,
                            block: result?.blockNumber ?? `RPC: Error`,
                        }
                })

                setResults(Object.values(contractsCreated))
            })
        }

        setIsLoading(false)
    }

    const showResultsPretty = () => {
        // Render the contracts, if any. And paginate them?
        const totalPages = Math.ceil(results.length / 10)
        return(<ItemList>
                {results.map((e) => {
                    return(
                        <Item key={e.hash}>
                            <a href={`https://${props.apiHostname.replace(`api.`, ``)}/tx/${e.hash}`} target="_blank" rel="nofollow noreferer">{e.addr}</a> (Block: {e.block})
                        </Item>
                    )})
                }
            </ItemList>)
    }

    const showResultsCodeBlock = () => {
        const res = JSON.stringify(results, null, 4)
        return(
            <>
            <CopyToClipboard text={res}>
                <button>Copy</button>
            </CopyToClipboard>
            <ShowResultsJson className="gatsby-highlight" data-language="json">
                <pre className="language-json">{res}</pre>
            </ShowResultsJson>
            </>
        )
    }

    const renderResults = () => {
        if(isLoading) {
            return(<Loading />)
        }

        if(error.length > 0) {
            return(<Error>{error}</Error>)
        }

        if(results.length === 0) {
            return(<Error>No contract creations found</Error>)
        }

        return (<>
            <ViewSelector
                selected={prettyView}
                onClick={() => setPrettyView(!prettyView)}
            >
                Show Pretty View
            </ViewSelector>
            <ViewSelector
                selected={!prettyView} 
                onClick={() => setPrettyView(!prettyView)}  
            >
                Show Raw Data View
            </ViewSelector>
        {
                prettyView 
                    ? showResultsPretty() 
                    : showResultsCodeBlock()
        }
        </>
        )
    }

    return (<>
        <h3>Results</h3>

        <strong>Address:</strong> {props.address} <br />
        <strong>RPC:</strong> {props.rpc}

        {totalToFetch > 0 && <TotalCreated>Total Contracts Created: <TextRainbow text={totalToFetch.toString()} /></TotalCreated>}

        {renderResults()}
    </>)
}

export default ShowResults